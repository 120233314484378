<template>
  <auth-wrapper>
    <v-card-title class="justify-center">
      <div>
        <div>Your account has been verified</div>
        <v-card-actions>
          <v-spacer />
          <default-button to="/">Log in now</default-button>
          <v-spacer />
        </v-card-actions>
      </div>
    </v-card-title>
  </auth-wrapper>
</template>

<script>
import AuthWrapper from "@/components/AuthWrapper";
import DefaultButton from "@/components/widgets/DefaultButton";
export default {
  components: { DefaultButton, AuthWrapper },
};
</script>
